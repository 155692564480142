import React from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { getUserByHasUserId } from "../../../../../../shared/selectors/user"
import { IAppState } from "../../../../../../app/appTypes"
import NameUser from "../../../../../../shared/components/names/NameUser"
import { IconAccept } from "../../../../../../svg/icons"

interface IProps {
  userId: string
  enabled: boolean
  toggleEnabled: () => void
}

const RequestConsentItem: React.FC<IProps> = (props): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, props))

  const colour = props.enabled
    ? "text-green-500"
    : "text-ch-gray-400"

  if(!user) return null
  const userActive = user.activeInCurrentTenant
  const handler = userActive ? props.toggleEnabled : null
  return (
    <div
      onClick={handler}
      className={classNames("flex ", {
        "cursor-pointer": userActive,
        "text-ch-gray-500": !userActive
      })}
    >
      <NameUser
        firstName={user.firstName}
        lastName={user.lastName}
        professionalTitle={user.professionalTitle}
        className={classNames("flex-1", { "font-bold": props.enabled })}
        disabled={!user.activeInCurrentTenant}
        isPending={user.pending}
      />
      {
        userActive
          ? <IconAccept className={`fill-current w-4 h-4 ml-4 ${colour}`} />
          : null
      }
    </div>
  )
}

export default RequestConsentItem
