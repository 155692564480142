import { createSelector } from "reselect"
import _ from "lodash"
import { IAppState } from "../../../app/appTypes"
import { IHaveMeetingId, IHaveMeetingIds, IHaveMeetingIdParam, IMeeting } from "../../../features/meeting/state/meetingTypes"
import { IHaveMeetingCaseId, IMeetingCase } from "../../../features/meetingCase/state/meetingCaseTypes"
import { IMeetingUser, IHaveMeetingUserId } from "../../../features/meetingUser/state/meetingUserTypes"
import { IUser } from "../../../features/user/state/userTypes"
import { getAllUsers } from "../user"

export const getAllMeetingIdsUiIndexPage = (state: IAppState): string[] => (state.ui.meetings.idsOnIndexPage)

export const getMeetingById = (state: IAppState, ownProps: IHaveMeetingId): IMeeting => {
  const meetingId = ownProps.meetingId
  return state.entities.meetings.byId[meetingId]
}

export const getMeetingsByIdsAsArray = (state: IAppState, ownProps: IHaveMeetingIds): IMeeting[] => {
  const meetingIds = ownProps.meetingIds
  return meetingIds.map(meetingId => {
    return state.entities.meetings.byId[meetingId]
  })
}

export const getAllMeetings = (state: IAppState): { [id: string]: IMeeting } => state.entities.meetings.byId

export const getAllMeetingsAsArray = (state: IAppState): IMeeting[] => {
  return state.entities.meetings.allIds.map((meetingId: string) => {
    return state.entities.meetings.byId[meetingId]
  })
}

export const getMeetingByParamId = (state: IAppState, ownProps: IHaveMeetingIdParam): IMeeting => {
  const meetingId = ownProps.match.params.meetingId
  return state.entities.meetings.byId[meetingId]
}

export const getMeetingCaseById = (state: IAppState, ownProps: IHaveMeetingCaseId): IMeetingCase =>
  state.entities.meetingCases.byId[ownProps.meetingCaseId] ?? null

export const getMeetingCaseByParamId = (state: IAppState, ownProps: IHaveMeetingCaseId): IMeetingCase => {
  const meetingCaseId = ownProps.meetingCaseId
  return state.entities.meetingCases.byId[meetingCaseId]
}

export const getAllMeetingCases = (state: IAppState): { [id: string]: IMeetingCase } => state.entities.meetingCases.byId

export const getMeetingCaseIds = createSelector(
  getMeetingById,
  (meeting): string[] => {
    if (!meeting) return []
    return meeting.meetingCases
  }
)

export const getMeetingUserIds = createSelector(
  getMeetingById,
  (meeting: IMeeting): string[] => {
    if (!meeting) return []
    return meeting.users
  }
)

export const getMeetingUsers = createSelector(
  getAllUsers,
  getMeetingUserIds,
  (allUsers, meetingUserIds): { [id: string]: IUser } => {
    if (!meetingUserIds) return null
    return _.pick(allUsers, meetingUserIds)
  }
)

export const getMeetingUserByMeetingUserId = (state: IAppState, ownProps: IHaveMeetingUserId): IMeetingUser => {
  return state.entities.meetingUsers.byId[ownProps.meetingUserId] ?? null
}

const meetingUserByUserId = (state: IAppState, meetingId: string, userId: string): IMeetingUser => {
  const meeting = state.entities.meetings.byId[meetingId]

  if (!meeting || !meeting.meetingUsers) return null

  return meeting.meetingUsers.map(x => state.entities.meetingUsers.byId[x]).find(user => {

    if (!user) return null
    return user.userId == userId || null
  })
}

export const getMeetingUserByCurrentUserId = (state: IAppState, meetingId: string): IMeetingUser => {
  return meetingUserByUserId(state, meetingId, state.userSession.id)
}

export const getMeetingUserByUserIdAndMeetingId = (state: IAppState, meetingId: string, userId: string): IMeetingUser => {
  return meetingUserByUserId(state, meetingId, userId)
}
