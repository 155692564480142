import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import TextAuthorDetails from "../../../../shared/components/text/TextAuthorDetails"
import TimeDaysFromNow from "../../../../shared/components/time/TimeDaysFromNow"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { getLatestTaskResponse, getTaskById } from "../../../../shared/selectors/task"
import { IconAccept } from "../../../../svg/icons"
import PatientNameWithLock from "../../../case/components/details/PatientNameWithLock"
import AssigneesList from "../shared/AssigneesList"

interface IProps {
  taskId: string
}

const TaskDetails: React.FC<IProps> = (props: IProps) => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  const latestTaskResponse = useSelector((state: IAppState) => getLatestTaskResponse(state, props))
  if (!task) return null

  const { t } = useTranslation(["shared", "task"])

  const renderTaskAction = (): JSX.Element => {
    return (
      <li>
        <strong className="font-semibold">{t("task:taskAction")}:</strong> {task.action}
      </li>
    )
  }

  const renderAssignedTo = (): JSX.Element => {
    return (
      <li>
        <strong className="font-semibold">{t("task:assignedTo")}:</strong> <AssigneesList taskId={props.taskId} />
      </li>
    )
  }

  const renderTaskCreated = (): JSX.Element => {
    return (
      <li className="text-ch-gray-500">
        {t("task:taskCreated")}: <TimeDefaultFormat utcTime={task.createdAt} />
      </li>
    )
  }

  const renderDueDate = (): JSX.Element => {
    if (task.isComplete) return null
    const dueDate = task.dueDate
      ? <> <TimeDefaultFormat utcTime={task.dueDate} /> <TimeDaysFromNow utcTime={task.dueDate} /> </>
      : t("task:dueDateNotSet")

    return (
      <li>
        <strong className="font-semibold">{t("task:dueDate")}:</strong> {dueDate}
      </li>
    )
  }

  const renderResponseAuthor = (): JSX.Element => {
    if (!latestTaskResponse) return null

    const renderLabel = (): string => {
      if (task.isComplete) return t("task:completedBy")
      return t("task:responseBy")
    }

    return (
      <li>
        <strong className="font-semibold">{renderLabel()}:</strong> <TextAuthorDetails
          firstName={latestTaskResponse.createdBy.firstName}
          lastName={latestTaskResponse.createdBy.lastName}
          createdAt={latestTaskResponse.createdAt}
        />
      </li>
    )
  }

  const renderLatestResponse = (): JSX.Element => {
    if (!latestTaskResponse) return null
    return (
      <li className="truncate">
        <strong className="font-semibold">{t("task:latestResponse")}:</strong> {latestTaskResponse.response}
      </li>
    )
  }

  const renderCompleteIcon = (): JSX.Element => {
    if (!task.isComplete) return null
    return (
      <li className="flex justify-center">
        <span className="text-green-500 mt-2">
          <IconAccept className="fill-current w-6 h-6" />
        </span>
      </li>
    )
  }

  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        <li>
          <PatientNameWithLock case={task.case} />
        </li>
        {renderTaskAction()}
        {renderAssignedTo()}
        {renderTaskCreated()}
      </ul>

      <ul className="col-span-1">
        {renderDueDate()}
        {renderLatestResponse()}
        {renderResponseAuthor()}
        {renderCompleteIcon()}
      </ul>
    </div>
  )
}

export default TaskDetails
