import { normalize, schema } from "normalizr"
import { call, put, select } from "redux-saga/effects"
import API from "../../apis/contextmeeting/api"
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"
import { displayUserError, displayUserSuccess } from "../../features/userMessage/state/userMessageActions"
import { getCurrentUserId } from "../selectors/user"
import Apm from "../../app/apm"
import { AnyAction } from "redux-saga"

export interface hasByUserId {
  byUserId: string
}

export function* checkCurrentUser({ byUserId }: hasByUserId): Generator {
  const currentUserId = yield select(getCurrentUserId)
  return (byUserId == currentUserId)
}

function putAction<T>(type: T, payload: unknown) {
  return put({
    type: type,
    payload: payload
  } as AnyAction)
}

export function* getNormalisedSingle(path: string, schema: schema.Entity | schema.Entity[]) {
  const response = yield call(API.get, path, withAuthHeader())
  if (schema) {
    const normalizedResponse = normalize(response.data, schema)
    return normalizedResponse
  }
  return response.data
}

export function* deleteNormalisedSingle(path: string, schema: schema.Entity) {
  const response = yield call(API.delete, path, withAuthHeader())
  if (schema) {
    const normalizedResponse = normalize(response.data, schema)
    return normalizedResponse
  }
  return response.data
}

export function* postNormalisedSingle(path: string, data: unknown, schema?: schema.Entity) {
  const response = yield call(API.post, path, data, withAuthHeader())
  if (schema) {
    const normalizedResponse = normalize(response.data, schema)
    return normalizedResponse
  }
  return response.data
}

export function* patchNormalisedSingle(path: string, data: unknown, schema?: schema.Entity) {
  const response = yield call(API.patch, path, data, withAuthHeader())
  if (schema) {
    const normalizedResponse = normalize(response.data, schema)
    return normalizedResponse
  }
  return response.data
}

export function* handleError<T>(type: T, data: unknown, messageKey?: string) {
  yield putAction(type, data)
  if (messageKey) {
    yield displayUserError(messageKey)
  }
}

export function* handleSuccess<T>(type: T, data: unknown, messageKey?: string) {
  yield putAction(type, data)
  if (messageKey) {
    yield displayUserSuccess(messageKey)
  }
}

export function* logError(message: string, error: unknown) {
  yield console.error(message, error)
  yield Apm.captureError(error as Error)
}
