/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormikContext } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { IRecommendationNewData } from "../../../../../assets/state/assetsTypes"
import { IUser } from "../../../../../user/state/userTypes"
import MentionField from "../../../../../../shared/components/forms/MentionField"
import Label from "../../../../../../shared/components/forms/Label"
import FieldError from "../../../../../../shared/components/forms/FieldError"
import RequestConsentItem from "./RequestConsentItem"
import DatePickerField from "../../../../../../shared/components/datePicker/DatePickerField"
import SvgIconTime from "../../../../../../svg/icons/IconTime"
import TimeInput from "../../../../../../shared/components/time/TimeInput"
import { getCaseMemberUserIds } from "../../../../../../shared/selectors/case"
import { useParams } from "react-router"
import { getMeetingCaseById, getMeetingById } from "../../../../../../shared/selectors/meeting"
import { IHaveMeetingCaseId } from "../../../../../meetingCase/state/meetingCaseTypes"
import { TenantConfigContext } from "../../../../../tenantConfig/contexts/TenantConfigContext"
import { useAppSelector as useSelector } from "../../../../../../shared/hooks"

interface IProps {
  mentionableUsers: { [id: string]: IUser }
  caseId: string
}

const RecommendationFields: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["shared", "case", "asset"])
  const tenantConfig = useContext(TenantConfigContext)
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const {errors, touched, values, handleChange, handleBlur, setFieldValue} = useFormikContext<IRecommendationNewData>()
  const requestConsentList = values.requestConsentUserIds

  const toggleEnabledForUser = (userId: string) => (
    () => {
      let newList: string[]
      if (requestConsentList.includes(userId)) {
        newList = requestConsentList.filter((id) => id != userId)
      } else {
        newList = requestConsentList.concat([userId])
      }
      setFieldValue("requestConsentUserIds", newList)
    }
  )

  let userList: string[]
  if (meetingCaseId) {
    const meetingCase = useSelector((state) => getMeetingCaseById(state, { meetingCaseId }))
    const meeting = useSelector((state) => getMeetingById(state, { meetingId: meetingCase.meeting }))
    userList = meeting.users
  } else {
    userList = useSelector((state) => getCaseMemberUserIds(state, props))
  }

  const approvalList = userList.map((userId: string) => {
    return (
      <div className="my-2" key={userId}>
        <RequestConsentItem userId={userId} key={userId} enabled={requestConsentList.includes(userId)} toggleEnabled={toggleEnabledForUser(userId)} />
      </div>
    )
  })

  const selectAll = () => {
    setFieldValue("requestConsentUserIds", userList)
  }

  return (
    <div>
      <div className="mb-3">
        <Label name="body" required={true}>
          {t("asset:recommendation")}
        </Label>
        <MentionField
          name="body"
          onBlur={handleBlur}
          users={props.mentionableUsers}
          value={values.body}
          setValue={(body: string) => { setFieldValue("body", body) }}
        />

        <FieldError errorMessage={errors.body as string} isVisible={errors.body && touched.body} />
      </div>

      <div className="mb-3">
        <div className="flex">
          <Label name="body" required={true} className={"flex-1"}>
            {tenantConfig.recommendationConsentHeader || t("asset:approvalRequiredFrom")}:
          </Label>
          <div onClick={selectAll} className="font-bold text-ch-blue-500 hover:text-ch-blue-600 uppercase inline-flex cursor-pointer">
            {t("shared:selectAll")}
          </div>
        </div>
        <div className="mb-2 py-2 px-3 rounded border border-ch-blue-alt-300 appearance-none">
          {approvalList}
          <FieldError errorMessage={errors.requestConsentUserIds as string} isVisible={(touched.requestConsentUserIds)} />
        </div>
      </div>

      <div className="mb-3">
        <div>
          <Label name="deadlineDate" required={true}>
            {t("asset:approvalDeadline")}:
          </Label>
          <div className="flex flex-wrap">
            <div className="flex mr-4 mb-1">
              <div className="flex flex-col">
                <DatePickerField name="deadlineDate" />
                <div className="my-1 ml-7">
                  <FieldError errorMessage={errors.deadlineDate as string} isVisible={(errors.deadlineDate && touched.deadlineDate) as boolean} />
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="flex space-between items-center">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <SvgIconTime className="text-ch-blue-500 mx-2 w-5 h-5" />
                    <TimeInput
                      name="deadlineTime"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="my-1">
                    <FieldError errorMessage={errors.deadlineTime as string} isVisible={errors.deadlineTime && touched.deadlineTime} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default RecommendationFields
